import React, {useRef, useMemo, useState} from "react"
import Layout from "../components/layout"
import SEO from "../components/seo"
import Campaign from "../images/campaign.png";
import Inea from "../images/logo/inea.png";
import Adamed from "../images/logo/adamed.png";
import Eobuwie from "../images/logo/eobuwie.png";
import Thyssenkrupp from "../images/logo/thyssenkrupp.png";
import Alcon from "../images/logo/alcon.png";
import Picture from "../components/picture";

//const API_URL = 'http://localhost:3000';
const API_URL = '';

const IndexPage = () => (
  <Layout>
    <SEO title="Ostendi Global" />
    <div className="ostendi">
      <div className="ostendi__image" data-aos="fade-right">
        <Picture path={Campaign} size={600}/>
      </div>
      <div className="ostendi__form" data-aos="fade-left">
        <h1>Odblokuj swój potencjał!</h1>
        <p className="text-grey">Znajdź organizację, która w pełni wykorzysta Twój potencjał!</p>
        <p className="text-grey">Zostaw swój kontakt, wyślemy do Ciebie link do kwestionariusza, dzięki któremu poznasz swoje ukryte talenty, a my pomożemy znaleźć Ci pracę.</p>
        <Form/>
      </div>
    </div>
    <div className="clients">
      <h3>Nasi zadowoleni klienci</h3>
      <div className="clients__logos">
        <Picture title="inea" path={Inea} size={150} />
        <Picture title="adamed" path={Adamed} size={150}/>
        <Picture title="eobuwie" path={Eobuwie} size={150}/>
        <Picture title="thyssenkrupp" path={Thyssenkrupp} size={150}/>
        <Picture title="aclon" path={Alcon} size={150}/>
      </div>
    </div>
  </Layout>
)

const Form = () => {
  const [name, setName] = useState("");
  const [surname, setSurname] = useState("");
  const [email, setEmail] = useState("");
  const [tel, setTel] = useState("");
  const [agree, setAgree] = useState(false);

  const formAlert = useRef(null);

  const inputConfig = useMemo(() => [
    {
      type: "text",
      name: "name",
      text: "Imię",
      value: name,
      onChange: setName,
    },
    {
      type: "text",
      name: "surname",
      text: "Nazwisko",
      value: surname,
      onChange: setSurname,
    },
    {
      type: "text",
      name: "email",
      text: "Email",
      value: email,
      onChange: setEmail,
    },
    {
      type: "text",
      name: "tel",
      text: "Telefon",
      value: tel,
      onChange: setTel,
    },
    {
      type: "checkbox",
      name: "agree",
      text: "Zgoda na przetwarzanie danych w celu marketingu produktów i usług Ostendi Global Sp. z o.o.<br> " +
        "Informacje o prawach osób, których dane osobowe są przetwarzane przez Ostendi Global Sp. z o.o. są <a href='https://www.ostendi.pl/informacje-o-prawach-osob-ktorych-dane-osobowe-sa-przetwarzane-przez-ostendi-polska/' target='_blank'/>tutaj.</a>",
      value: agree,
      onChange: () => setAgree((prevState) => !prevState),
    }
  ], [agree, email, name, tel, setTel, setAgree, setEmail, setName, setSurname, surname]);

  const submitForm = (e) => {
    e.preventDefault();
    if (!name || !surname || !email || !tel) {
      formAlert.current.innerText = 'Wszystkie pola muszą zostać wypełnione';
    } else if (!agree) {
      formAlert.current.innerText = 'Zgoda jest wymagana';
    } else if (!(/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(email))) {
      formAlert.current.innerText = 'Adres email jest niepoprawny';
    } else if (!(/\(?([0-9]{3})\)?([ .-]?)([0-9]{3})\2([0-9]{3,4})/.test(tel))) {
      formAlert.current.innerText = 'Numer telefonu jest niepoprawny';
    } else {
      formAlert.current.innerText = '';
      fetch(API_URL + '/confirmation', {
        method: "POST",
        body: JSON.stringify({
          name, surname, email, agree, tel,
          subject: "Wiadomość z akcji promocyjnej talent.ostendi.pl"
        }),
        headers: {
          'Content-Type': 'application/json'
        },
      })
        .then(response => {
          if (response.status === 200) {
            alert("Dziękujemy. Wiadomość została wysłana.");
            resetForm();
          } else {
            alert("Uzupełnij poprawnie wszystkie pola lub spróbuj później.")
          }
        })
        .catch((error) => {
          alert("Wiadomość nie została wysłana, proszę spróbować później.")
        });
    }
  }

  const resetForm = () => {
    setName("");
    setSurname("");
    setEmail("");
    setTel("");
    setAgree(false);
  }

  return (
    <>
      <div className="form__error" ref={formAlert}></div>
      <form className="form" method="post" onSubmit={submitForm}>
        {inputConfig.map((input) => {
          return <Input key={input.name} type={input.type} name={input.name} text={input.text} value={input.value} onChange={(e) => input.onChange(e.target.value)}/>
        })}
        <div className="form__footer">
          <button className="form__button" type="submit">Wyślij</button>
        </div>
      </form>
    </>
  )
}

const Input = ({type, name, text, value, onChange}) => {
  switch(type) {
  case 'checkbox':
    return <div className="form__group">
      <label className="form__checkbox">
        <input type={type} checked={value} onChange={onChange} placeholder={text} name={name}/>
        <small dangerouslySetInnerHTML={{__html: text}}/>
      </label>
    </div>
    break;
  default:
    return <div className="form__group">
      <label className="form__label" htmlFor={name}>{text}</label>
      <input type={type} value={value} onChange={onChange} placeholder={text} name={name} className="form__control"/>
    </div>
    break;
  }
}



export default IndexPage
